<template>
  <div v-if="!isLoading">
    <nav class="breadcrumb" aria-label="breadcrumbs">
      <ul>
        <li>
          <router-link :to="{ name: 'Dashboard' }">
            <b-icon icon="home"></b-icon>
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'AddOnsIndex' }">
            Add-Ons
          </router-link>
        </li>
        <li class="has-text-primary-dark-blue">
          <span class="px-2" v-if="$route.path.includes('/addnewaddon')">
            Add New Add-On
          </span>
          <span class="px-2" v-else>
            Edit Add-On
          </span>
        </li>
      </ul>
    </nav>

    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(save)">
        <div class="buttons" style="justify-content: flex-end">
          <b-button
            @click="cancelAction()"
            style="border: none;background-color:transparent"
          >
            Batalkan
          </b-button>

          <b-button type="is-primary-medium-blue" native-type="submit">
            Simpan
          </b-button>
        </div>

        <ValidationProvider
          name="Nama Add-On"
          rules="required"
          v-slot="{ errors }"
        >
          <b-field>
            <template slot="label">
              <span class="is-size-7">
                Nama Add-On
              </span>
            </template>
            <b-input
              v-model="form.name"
              placeholder="Masukan Nama Add-On"
              type="text"
            ></b-input>
          </b-field>
          <p class="has-text-red" v-if="errors != null">
            {{ errors[0] }}
          </p>
        </ValidationProvider>

        <ValidationProvider
          name="Deskripsi"
          rules="required"
          v-slot="{ errors }"
        >
          <b-field>
            <template slot="label">
              <span class="is-size-7">
                Deskripsi
              </span>
            </template>
            <b-input
              v-model="form.description"
              placeholder="Masukan Deskripsi"
              type="text"
            ></b-input>
          </b-field>
          <p class="has-text-red" v-if="errors != null">
            {{ errors[0] }}
          </p>
        </ValidationProvider>

        <ValidationProvider name="Detail" rules="required" v-slot="{ errors }">
          <b-field>
            <template slot="label">
              <span class="is-size-7">
                Detail
              </span>
            </template>
            <b-input
              v-model="form.detail"
              placeholder="Masukan Detail"
              type="text"
            ></b-input>
          </b-field>
          <p class=" mb-4 has-text-red" v-if="errors != null">
            {{ errors[0] }}
          </p>
        </ValidationProvider>

        <ValidationProvider
          name="Status Add-On"
          rules="required"
          v-slot="{ errors }"
        >
          <b-field>
            <template slot="label">
              <span class="is-size-7">
                Status Add-On
              </span>
            </template>
            <b-select
              placeholder="Pilih Gratis / Berbayar"
              v-model="form.status"
              expanded
              @input="changeStatusId"
            >
              <option
                v-for="option in status"
                :value="option.value"
                :key="option.value"
                :disabled="option.disabled"
              >
                {{ option.text }}
              </option>
            </b-select>
          </b-field>
          <p class="mx-4 mb-4 has-text-red" v-if="errors != null">
            {{ errors[0] }}
          </p>
        </ValidationProvider>

        <ValidationProvider
          v-if="form.status == 'Paid'"
          name="Harga"
          rules="required"
          v-slot="{ errors }"
        >
          <b-field>
            <template slot="label">
              <span class="is-size-7">
                Harga
              </span>
            </template>
            <b-input
              v-model="priceStr"
              placeholder="Masukan Harga"
              type="text"
              :value="form.price"
              @keypress.native="isNumber($event)"
              @input="updatePrice"
            ></b-input>
          </b-field>
          <p class="has-text-red" v-if="errors != null">
            {{ errors[0] }}
          </p>
        </ValidationProvider>

        <ValidationProvider
          v-if="form.status == 'Paid'"
          name="Discount"
          rules="required"
          v-slot="{ errors }"
        >
          <b-field>
            <template slot="label">
              <span class="is-size-7">
                Diskon
              </span>
            </template>
            <b-input
              v-model="discountStr"
              placeholder="Masukan Harga Diskon"
              type="text"
              :value="form.discount"
              @keypress.native="isNumber($event)"
              @input="updateDiscount"
            ></b-input>
          </b-field>
          <p class="has-text-red" v-if="errors != null">
            {{ errors[0] }}
          </p>
        </ValidationProvider>

        <ValidationProvider
          v-if="form.status == 'Paid'"
          name="Masa Aktif"
          rules="required"
          v-slot="{ errors }"
        >
          <b-field>
            <template slot="label">
              <span class="is-size-7">
                Masa Aktif (Hari)
              </span>
            </template>
            <b-input
              v-model="expiryStr"
              placeholder="Masukan Jumlah Masa Aktif (hari)"
              type="text"
              :value="form.expiry"
              @keypress.native="isNumber($event)"
              @input="updateExpiry"
            ></b-input>
          </b-field>
          <p class="has-text-red" v-if="errors != null">
            {{ errors[0] }}
          </p>
        </ValidationProvider>

        <ValidationProvider name="Gambar">
          <b-field>
            <template slot="label">
              <span class="is-size-7">
                Gambar
              </span>
            </template>
            <b-upload v-model="file" expanded accept=".png" @input="checkImage">
              <a class="button is-primary-medium-blue is-fullwidth">
                <span v-if="form.imageUrl == null || form.imageUrl == ''">
                  {{ file.name || 'Tekan untuk upload' }}
                </span>
                <span v-else>{{ file.name || imageName }}</span>
              </a>
            </b-upload>
          </b-field>
        </ValidationProvider>

        <ValidationProvider name="Status" rules="required" v-slot="{ errors }">
          <b-field class="mt-3">
            <template slot="label">
              <span class="is-size-7">
                Status
              </span>
            </template>
            <b-select
              placeholder="Pilih Status"
              v-model="form.isActive"
              expanded
              @input="changeIsActiveId"
            >
              <option
                v-for="option in isActive"
                :value="option.value"
                :key="option.value"
                :disabled="option.disabled"
              >
                {{ option.text }}
              </option>
            </b-select>
          </b-field>
          <p class="mx-4 mb-4 has-text-red" v-if="errors != null">
            {{ errors[0] }}
          </p>
        </ValidationProvider>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
export default {
  data() {
    return {
      file: {},
      form: {
        id: '',
        name: '',
        description: '',
        detail: '',
        price: '',
        discount: '',
        expiry: '',
        imageUrl: '',
        isActive: 1,
        status: 'Free',
      },
      priceStr: '',
      expiryStr: '',
      discountStr: '',

      imageName: '',
      image: false,
      isLoading: true,

      status: [
        { text: 'Pilih Status', value: null, disabled: true },
        { text: 'Gratis', value: 'Free' },
        { text: 'Berbayar', value: 'Paid' },
      ],

      isActive: [
        { text: 'Pilih Status', value: null, disabled: true },
        { text: 'Aktif', value: 1 },
        { text: 'Non-Aktif', value: 0 },
      ],
    }
  },
  created() {
    if (this.$route.path !== '/addons/addnewaddon') {
      this.getAddOnByID(this.$route.params.addonid)
    }
    this.isLoading = false
  },
  methods: {
    checkImage: function() {
      this.image = true
    },
    getAddOnByID: function(id) {
      this.$store.dispatch('getAddOnByID', id).then(data => {
        this.form = data
        this.form.isActive = data.isActive

        //commas price
        this.priceStr = this.form.price
        let respriceStr = this.priceStr
          .toString()
          .replace(/\D/g, '')
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        this.priceStr = respriceStr

        if (this.form.expiry != null) {
          //commas expiry
          this.expiryStr = this.form.expiry
          let resexpiryStr = this.expiryStr
            .toString()
            .replace(/\D/g, '')
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          this.expiryStr = resexpiryStr
        }
        //commas discount
        this.discountStr = this.form.discount
        let resdiscountStr = this.discountStr
          .toString()
          .replace(/\D/g, '')
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        this.discountStr = resdiscountStr

        if (this.form.imageUrl != null) {
          var imageName = this.form.imageUrl.split('/')
          this.imageName = imageName.slice(-1)[0]
        } else {
          this.imageName = ''
        }
        this.image = true
        this.isLoading = false
      })
    },
    updatePrice(event) {
      event = String(event).replace(/,/g, '')
      this.form.price = event
      // comas price
      this.priceStr = this.form.price
      let respriceStr = this.priceStr
        .toString()
        .replace(/\D/g, '')
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      this.priceStr = respriceStr
    },
    updateExpiry(event) {
      event = String(event).replace(/,/g, '')
      this.form.expiry = event
      // comas expiry
      this.expiryStr = this.form.expiry
      let resexpiryStr = this.expiryStr
        .toString()
        .replace(/\D/g, '')
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      this.expiryStr = resexpiryStr
    },
    updateDiscount(event) {
      event = String(event).replace(/,/g, '')
      this.form.discount = event
      // comas discount
      this.discountStr = this.form.discount
      let resdiscountStr = this.discountStr
        .toString()
        .replace(/\D/g, '')
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      this.discountStr = resdiscountStr
    },
    isNumber: function(evt) {
      evt = evt ? evt : window.event
      var charCode = evt.which ? evt.which : evt.keyCode
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault()
      } else {
        return true
      }
    },
    save: function() {
      if (this.image == false) {
        this.$buefy.toast.open({
          message: 'Gambar Tidak Boleh Kosong',
          type: 'is-danger',
        })
      } else {
        this.$buefy.dialog.confirm({
          title: 'Konfirmasi',
          message: 'Apakah Anda Yakin Menyimpan Data Ini?',
          confirmText: 'Ya',
          hasIcon: true,
          onConfirm: () => {
            var files = this.file
            let formData = new FormData()
            formData.append('file', files)
            let id = this.form.id
            let name = this.form.name
            let description = this.form.description
            let detail = this.form.detail
            let price = parseInt(this.form.price)
            let discount = parseInt(this.form.discount)
            let expiry = parseInt(this.form.expiry)
            let is_active = this.form.isActive
            let status = this.form.status
            let image_name = this.imageName

            var json = {
              name,
              expiry,
              price,
              discount,
              description,
              detail,
              is_active,
              status,
              image_name,
            }
            formData.append('data', JSON.stringify(json))
            if (id === '') {
              this.$store
                .dispatch('addNewAddOn', { formData })
                .then()
                .catch(err => console.log(err))
            } else {
              this.$store
                .dispatch('editAddOn', {
                  id,
                  formData,
                })
                .then()
                .catch(err => console.log(err))
            }
          },
        })
      }
    },
    cancelAction() {
      this.$buefy.dialog.confirm({
        title: 'Konfirmasi',
        message: 'Apakah anda yakin membatalkan aksi ini?',
        confirmText: 'Ya',
        type: 'is-warning',
        hasIcon: true,
        onConfirm: () => {
          this.$router.push('/addons')
        },
      })
    },
    changeStatusId(value) {
      this.form.status = value
    },
    changeIsActiveId(value) {
      this.form.isActive = value
    },
  },
}
</script>

<style></style>
