var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.isLoading)?_c('div',[_c('nav',{staticClass:"breadcrumb",attrs:{"aria-label":"breadcrumbs"}},[_c('ul',[_c('li',[_c('router-link',{attrs:{"to":{ name: 'Dashboard' }}},[_c('b-icon',{attrs:{"icon":"home"}})],1)],1),_c('li',[_c('router-link',{attrs:{"to":{ name: 'AddOnsIndex' }}},[_vm._v(" Add-Ons ")])],1),_c('li',{staticClass:"has-text-primary-dark-blue"},[(_vm.$route.path.includes('/addnewaddon'))?_c('span',{staticClass:"px-2"},[_vm._v(" Add New Add-On ")]):_c('span',{staticClass:"px-2"},[_vm._v(" Edit Add-On ")])])])]),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.save)}}},[_c('div',{staticClass:"buttons",staticStyle:{"justify-content":"flex-end"}},[_c('b-button',{staticStyle:{"border":"none","background-color":"transparent"},on:{"click":function($event){return _vm.cancelAction()}}},[_vm._v(" Batalkan ")]),_c('b-button',{attrs:{"type":"is-primary-medium-blue","native-type":"submit"}},[_vm._v(" Simpan ")])],1),_c('ValidationProvider',{attrs:{"name":"Nama Add-On","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',[_c('template',{slot:"label"},[_c('span',{staticClass:"is-size-7"},[_vm._v(" Nama Add-On ")])]),_c('b-input',{attrs:{"placeholder":"Masukan Nama Add-On","type":"text"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],2),(errors != null)?_c('p',{staticClass:"has-text-red"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Deskripsi","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',[_c('template',{slot:"label"},[_c('span',{staticClass:"is-size-7"},[_vm._v(" Deskripsi ")])]),_c('b-input',{attrs:{"placeholder":"Masukan Deskripsi","type":"text"},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}})],2),(errors != null)?_c('p',{staticClass:"has-text-red"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Detail","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',[_c('template',{slot:"label"},[_c('span',{staticClass:"is-size-7"},[_vm._v(" Detail ")])]),_c('b-input',{attrs:{"placeholder":"Masukan Detail","type":"text"},model:{value:(_vm.form.detail),callback:function ($$v) {_vm.$set(_vm.form, "detail", $$v)},expression:"form.detail"}})],2),(errors != null)?_c('p',{staticClass:" mb-4 has-text-red"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Status Add-On","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',[_c('template',{slot:"label"},[_c('span',{staticClass:"is-size-7"},[_vm._v(" Status Add-On ")])]),_c('b-select',{attrs:{"placeholder":"Pilih Gratis / Berbayar","expanded":""},on:{"input":_vm.changeStatusId},model:{value:(_vm.form.status),callback:function ($$v) {_vm.$set(_vm.form, "status", $$v)},expression:"form.status"}},_vm._l((_vm.status),function(option){return _c('option',{key:option.value,attrs:{"disabled":option.disabled},domProps:{"value":option.value}},[_vm._v(" "+_vm._s(option.text)+" ")])}),0)],2),(errors != null)?_c('p',{staticClass:"mx-4 mb-4 has-text-red"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}],null,true)}),(_vm.form.status == 'Paid')?_c('ValidationProvider',{attrs:{"name":"Harga","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',[_c('template',{slot:"label"},[_c('span',{staticClass:"is-size-7"},[_vm._v(" Harga ")])]),_c('b-input',{attrs:{"placeholder":"Masukan Harga","type":"text","value":_vm.form.price},on:{"input":_vm.updatePrice},nativeOn:{"keypress":function($event){return _vm.isNumber($event)}},model:{value:(_vm.priceStr),callback:function ($$v) {_vm.priceStr=$$v},expression:"priceStr"}})],2),(errors != null)?_c('p',{staticClass:"has-text-red"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}],null,true)}):_vm._e(),(_vm.form.status == 'Paid')?_c('ValidationProvider',{attrs:{"name":"Discount","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',[_c('template',{slot:"label"},[_c('span',{staticClass:"is-size-7"},[_vm._v(" Diskon ")])]),_c('b-input',{attrs:{"placeholder":"Masukan Harga Diskon","type":"text","value":_vm.form.discount},on:{"input":_vm.updateDiscount},nativeOn:{"keypress":function($event){return _vm.isNumber($event)}},model:{value:(_vm.discountStr),callback:function ($$v) {_vm.discountStr=$$v},expression:"discountStr"}})],2),(errors != null)?_c('p',{staticClass:"has-text-red"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}],null,true)}):_vm._e(),(_vm.form.status == 'Paid')?_c('ValidationProvider',{attrs:{"name":"Masa Aktif","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',[_c('template',{slot:"label"},[_c('span',{staticClass:"is-size-7"},[_vm._v(" Masa Aktif (Hari) ")])]),_c('b-input',{attrs:{"placeholder":"Masukan Jumlah Masa Aktif (hari)","type":"text","value":_vm.form.expiry},on:{"input":_vm.updateExpiry},nativeOn:{"keypress":function($event){return _vm.isNumber($event)}},model:{value:(_vm.expiryStr),callback:function ($$v) {_vm.expiryStr=$$v},expression:"expiryStr"}})],2),(errors != null)?_c('p',{staticClass:"has-text-red"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}],null,true)}):_vm._e(),_c('ValidationProvider',{attrs:{"name":"Gambar"}},[_c('b-field',[_c('template',{slot:"label"},[_c('span',{staticClass:"is-size-7"},[_vm._v(" Gambar ")])]),_c('b-upload',{attrs:{"expanded":"","accept":".png"},on:{"input":_vm.checkImage},model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}},[_c('a',{staticClass:"button is-primary-medium-blue is-fullwidth"},[(_vm.form.imageUrl == null || _vm.form.imageUrl == '')?_c('span',[_vm._v(" "+_vm._s(_vm.file.name || 'Tekan untuk upload')+" ")]):_c('span',[_vm._v(_vm._s(_vm.file.name || _vm.imageName))])])])],2)],1),_c('ValidationProvider',{attrs:{"name":"Status","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{staticClass:"mt-3"},[_c('template',{slot:"label"},[_c('span',{staticClass:"is-size-7"},[_vm._v(" Status ")])]),_c('b-select',{attrs:{"placeholder":"Pilih Status","expanded":""},on:{"input":_vm.changeIsActiveId},model:{value:(_vm.form.isActive),callback:function ($$v) {_vm.$set(_vm.form, "isActive", $$v)},expression:"form.isActive"}},_vm._l((_vm.isActive),function(option){return _c('option',{key:option.value,attrs:{"disabled":option.disabled},domProps:{"value":option.value}},[_vm._v(" "+_vm._s(option.text)+" ")])}),0)],2),(errors != null)?_c('p',{staticClass:"mx-4 mb-4 has-text-red"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}],null,true)})],1)]}}],null,false,3171864436)})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }